import classNames from "classnames";
import React from "react";
import ListSelectionButton from "./ListSelectionButton";

export interface HeadProps {
    /**
     * List of the same fields passed to the List component.
     * See List for more details.
     */
    fields: {
        key: number | symbol | string;
        title: string;
    }[];

    /**
     * Whether the header should have a "select all" button on the left.
     * Inherited by the parent List.
     */
    hasSelectButton: boolean;

    /**
     * Whether the header should have a selected styling.
     * The "select all" button will appear as selected when all rows are selected, and
     * will appear as unselected otherwise.
     * The logic is managed by the parent List.
     */
    allSelected: boolean;
    someSelected: boolean;

    /**
     * What happens when the select button on the left is clicked.
     * It will usually select/deselect all visible rows.
     */
    onSelect: () => void;

    /**
     * Controls whether there should be a margin applied to the header in case the rows have a dropdown button to
     * expand the row. This is controlled by the parent, who knows whether there are expandable rows or not.
     */
    hasDropdownButton?: boolean;

    /**
     * if present, it sets the number of columns of the table
     */
    listType?: string;
}

/**
 * The header of a List component.
 * It will show the titles of the fields passed in the properties.
 */
const ListHeader: React.FC<HeadProps> = ({
    fields,
    hasSelectButton,
    allSelected,
    someSelected,
    listType,
    onSelect,
    hasDropdownButton = false,
}) => {
    let gridClass = "";
    let fieldToHide1 = "";
    let fieldToHide2 = "";
    let fieldToHide3 = "";
    let fieldToHide4 = "";
    let responsiveClass = "";

    switch (listType) {
        case "travellist":
            gridClass = "grid-cols-travellist";
            fieldToHide1 = "reference";
            responsiveClass = "hidden lg:block";
            break;
        case "documlist":
            gridClass = "grid-cols-documlist--responsive lg:grid-cols-documlist";
            fieldToHide1 = "size";
            responsiveClass = "hidden lg:block";
            break;
        case "reservationlist":
            gridClass = "grid-cols-reservationlist--responsive xl:grid-cols-reservationlist";
            fieldToHide1 = "title";
            fieldToHide2 = "amount_travelers";
            fieldToHide3 = "id";
            responsiveClass = "hidden xl:block";
            break;
        case "clientlist":
            gridClass = "grid-cols-clientlist--responsive xl:grid-cols-clientlist";
            fieldToHide1 = "moken";
            fieldToHide2 = "rating";
            responsiveClass = "hidden xl:block";
            break;
        case "tarifarios":
            gridClass = "grid-cols-tarifarios--responsive xl:grid-cols-tarifarios";
            fieldToHide1 = "moken";
            fieldToHide2 = "rating";
            responsiveClass = "hidden xl:block";
            break;
        case "tarifarios-providers":
            gridClass = "grid-cols-tarifarios-providers--responsive xl:grid-cols-tarifarios-providers";
            fieldToHide1 = "travel_type";
            responsiveClass = "hidden xl:block";
            break;
        case "destinationlist":
            gridClass = "grid-cols-destinationlist--responsive xl:grid-cols-destinationlist";
            fieldToHide1 = "external";
            fieldToHide2 = "provider";
            responsiveClass = "hidden xl:block";
            break;
        case "importItinerarylist":
            gridClass = "grid-cols-importItinerarylist--responsive xl:grid-cols-importItinerarylist";
            fieldToHide1 = "itinerary_days";
            fieldToHide2 = "travel_type";
            responsiveClass = "hidden xl:block";
            break;
        case "templatelist":
            gridClass = "grid-cols-templatelist--responsive xl:grid-cols-templatelist";
            fieldToHide1 = "travel_type";
            fieldToHide2 = "public";
            responsiveClass = "hidden xl:block";
            break;
        case "tempdestinationlist":
            gridClass = "grid-cols-tempdestinationlist--responsive lg:grid-cols-tempdestinationlist";
            fieldToHide1 = "days";
            responsiveClass = "hidden lg:block";
            break;
        case "userlist":
            gridClass = "grid-cols-userlist";
            responsiveClass = "hidden sm:block";
            break;

        case "morecontactlist":
            gridClass = "grid-cols-morecontactlist--responsive sm:grid-cols-morecontactlist";
            fieldToHide1 = "type";
            fieldToHide2 = "destinations";
            fieldToHide3 = "specialties";
            fieldToHide4 = "languages";
            responsiveClass = "hidden sm:block";
            break;

        case "teamlist":
            gridClass = "grid-cols-teamlist";
            break;
        case "inboxlist":
            gridClass = "grid-cols-inboxlist--responsive xl:grid-cols-inboxlist";
            fieldToHide1 = "reference";
            fieldToHide2 = "amount_travelers";
            responsiveClass = "hidden xl:block";
            break;
        case "askproviderlist":
            gridClass = "grid-cols-askproviderlist--responsive xl:grid-cols-askproviderlist";
            fieldToHide1 = "external";
            fieldToHide2 = "provider";
            responsiveClass = "hidden xl:block";
            break;
        default:
            break;
    }
    return (
        <div className="flex w-full">
            {hasSelectButton && (
                <ListSelectionButton selected={someSelected} onClick={onSelect} indeterminate={!allSelected} />
            )}

            <div className={`grid ${gridClass} auto-cols-fr items-center w-full h-12`}>
                {fields.map((field) => (
                    <div
                        className={classNames(
                            "flex w-full text-xs text-grey-light-1 font-thin font-sans",
                            (field.key === fieldToHide1 ||
                                field.key === fieldToHide2 ||
                                field.key === fieldToHide3 ||
                                field.key === fieldToHide4 ||
                                field.key === fieldToHide3) &&
                                responsiveClass
                        )}
                        key={String(field.key)}
                    >
                        <div className="flex items-center justify-start">{field.title}</div>
                    </div>
                ))}
            </div>
            {hasDropdownButton && <div className="w-10 px-2" />}
        </div>
    );
};

export default ListHeader;
