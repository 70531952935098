import React from "react";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import { ModelRef } from "../types";

export interface TagProps {
    /**
     * Text for the body of the tag
     */
    label: string | ModelRef;
    /**
     * Which styling should be applied to the tag.
     * Yellow has yellow background and black text.
     * Grey has a dark grey background and black text.
     * Platinum has a platinum background and light grey text.
     */
    type?: "yellow" | "grey" | "platinum";
    /**
     * If defined, the tag will be clickable and this handler will be called upon click.
     */
    onClick?: () => void;
    /**
     * If defined, the tag will have a cross on the right that when clicked will call
     * this handler.
     */
    onDelete?: (() => void) | false;
}

/**
 * Tag element.
 */
const Tag: React.FC<TagProps> = ({ label, type = "grey", onClick, onDelete }) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
        className={classNames(
            "relative flex items-center px-2.5 py-1 rounded-md ",
            type === "yellow" && "bg-yellow",
            type === "grey" && "bg-grey-dark-2 text-white",
            type === "platinum" && "bg-platinum-2",
            onClick && "cursor-pointer"
        )}
    >
        {onClick && (
            <div
                className="w-full h-full absolute"
                onClick={onClick}
                onKeyPress={onClick}
                role="button"
                tabIndex={0}
                aria-label="Tag"
            />
        )}
        <span className="text-sm">{label}</span>
        {onDelete ? (
            <Icon
                sx={{ fontSize: "18px" }}
                className="font-light cursor-pointer ml-3 z-10 text-xs relative top-[1px]"
                onClick={onDelete}
            >
                close
            </Icon>
        ) : null}
    </div>
);

export default Tag;
