/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import download from "downloadjs";
import Tooltip from "@mui/material/Tooltip";
import { ChatBubble } from "@mui/icons-material";
import axios, { AxiosError } from "axios";
import axiosApi from "../axiosApi";
import { RootState } from "../store";
import Button from "../components/Button";
import Container from "../components/Container";
import List from "../components/List/List";
import Pagination from "../components/Pagination";
import ScreenTemplate from "../components/ScreenTemplate";
import SearchBox from "../components/SearchBox";
import TabController from "../components/Tab/TabController";
import Tab from "../components/Tab/Tab";
import { dataPending, saveData } from "../redux/reservations";

import {
    AttachmentData,
    AttachmentRow,
    DayBlockData,
    ModelRef,
    ReservationDetailData,
    MokenImage,
    ReservationState,
    ContactMinimalData,
    EmployeeData,
    ContactFullData,
    HistoryChannelData,
} from "../types";
import useAlert from "../useAlert";
import TextInput from "../components/TextInput";
import TagGroup from "../components/TagGroup";
import FileUploadZone from "../components/FileUploadZone";
import IconButton from "../components/IconButton";
import {
    formatBytes,
    formatDate,
    formatDateISO,
    getChoice,
    getItineraryDays,
    getItineraryPdfName,
    getLowestInvalidId,
    getAvatarWithName,
} from "../utils";
import useModelRef from "../useModelRef";
import Selection from "../components/Selection";
import Spinner from "../components/spinners/Spinner";
import Dropdown from "../components/Dropdown/Dropdown";
import DropdownItem from "../components/Dropdown/DropdownItem";
import HistoryMessage from "../components/HistoryMessage";
import HistoryInput from "../components/HistoryInput";
import { useAuth } from "../auth";
import ClientSelectModal from "../components/modals/ClientSelectModal";
import ItineraryTab from "./ItineraryTab";
import DestinationsTab from "./DestinationsTab";
import PricingSection from "./PricingSection";
import LoadingModal from "../components/modals/LoadingModal";
import TermsTab from "../components/TermsTab";
import Checkbox from "../components/Checkbox";
import SendTab from "./SendScreen";
import Tag from "../components/Tag";
import StateBadge from "../components/StateBadge";
import { openModel, closeModel, showName } from "../redux/header";
import DateRangePicker from "../components/DateRangePicker";
import ImageSelector from "../components/ImageSelector";
import ReservationPanel from "../components/ReservationPanel";
import AssigneeSelection from "../components/AssigneeSelection";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import "./css/screens.css";

const PAGE_SIZE = 10;

/**
 * Reduced info for the list view.
 * The detail view has a lot more info so it is fetched separately when a reservation is opened.
 */
interface ReservationListData {
    id: number;
    title: string;
    first_client: ContactMinimalData;
    thumbnail: string;
    state: ReservationState;
    countries: Array<ModelRef>;
    travel_start_date: Date;
    itinerary_days: number;
    amount_travelers: number;
    comments: string;
}

interface ReservationRow {
    id: number;
    first_client_full_name: React.ReactNode;
    title: string;
    destinations: React.ReactNode;
    state: React.ReactNode;
    travel_start_date: string;
    itinerary_days: number;
    amount_travelers: number;
    comments: React.ReactNode;
}

interface HistoryMessageOwnerData {
    id: number;
    full_name: string;
    is_user: boolean;
    profile_image?: MokenImage;
    company?: string;
}

interface HistoryMessageData {
    email_sent: boolean;
    owner: HistoryMessageOwnerData;
    message: string;
    is_notification: boolean;
    create_datetime: Date;
    attachments?: AttachmentData[];
}

const reservationFields: {
    key: keyof ReservationRow;
    title: string;
}[] = [
    {
        key: "id",
        title: "Nº exp.",
    },
    {
        key: "first_client_full_name",
        title: "Nombre",
    },
    {
        key: "title",
        title: "Título",
    },
    {
        key: "amount_travelers",
        title: "Pax",
    },
    {
        key: "destinations",
        title: "Destinos",
    },
    {
        key: "state",
        title: "Estado",
    },
    {
        key: "travel_start_date",
        title: "Fecha de viaje",
    },
    {
        key: "itinerary_days",
        title: "Días",
    },
    {
        key: "comments",
        title: "Comentarios",
    },
];

const attachmentFields: {
    key: keyof AttachmentRow;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "size",
        title: "Tamaño",
    },
    {
        key: "timestamp",
        title: "Fecha de subida",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

interface LocationState {
    reservation: ReservationDetailData;

    /**
     * If coming from another screen (client, inbox...) we want to maintain the reservation
     * open as we navigate to this screen.
     */
    keepOpen: boolean;

    /**
     * Client info if creating a reservation from a client screen.
     * A new reservation will be created with the client info pre-filled with this client.
     */
    client: ModelRef;
}

interface ReservationScreenProps {
    /**
     * Separates the type of view that will be shown depending on the reservation state that will be fetched.
     * Reservations are just confirmed quotations.
     */
    type: "quotation" | "reservation";
}

const ReservationScreen: React.FC<ReservationScreenProps> = ({ type }) => {
    const locationState = useLocation().state as LocationState;
    const [isSidePanelOpened, setSidePanelIsopen] = useState(false);
    const scrollableSection = useRef<HTMLDivElement>(null);

    const scrollDownView = useCallback(() => {
        setTimeout(() => {
            scrollableSection.current?.scrollTo(0, scrollableSection.current.scrollHeight);
        }, 0);
    }, []);

    // List data for currently loaded reservations
    const [reservations, setReservations] = useState<Array<ReservationListData> | null>(null);
    // Full data for the currently open reservation
    const [reservationData, setReservationData] = useState<ReservationDetailData | null>(locationState?.reservation);
    const [itinerary, setItinerary] = useState<Array<DayBlockData>>(locationState?.reservation?.itinerary || []);
    const [employees, setEmployees] = useState<EmployeeData[] | null>(null);
    const [isCreatingNewReservation, setIsCreatingNewReservation] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedRows, setSelectedRows] = useState(new Set<number>());
    const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
    const [travelEndTime, setTravelEndTime] = useState<Date>();
    const [optionsDropdownVisible, setOptionsDropdownVisible] = useState(false);
    const [, setFieldErrors] = useState(new Map<string, string>());
    const [keepOpen, setKeepOpen] = useState(locationState?.keepOpen);
    const [assignedFilter, setAssignedFilter] = useState(false);
    const [firstCountryImage, setFirstCountryImage] = useState<MokenImage | null>(null);
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);
    const [commentsTooltipVisible, setCommentsTooltipVisible] = useState<number | null>(null);
    const [historyChannels, setHistoryChannels] = useState<Array<HistoryChannelData>>([]);
    const [currentChannel, setCurrentChannel] = useState<number>(0);
    const [channelInfo, setChannelInfo] = useState<string>("Histórico expediente");
    const firstinit = useRef<boolean>(true);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);
    const [isDoubleCheckDisabled, setIsdoubleCheckDisabled] = useState<boolean>(false);
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const [allTagsVisible, setAllTagsVisible] = useState<number | null>(null);

    // Search box
    const [historyInputValue, setHistoryInputValue] = useState("");
    const [historyMessages, setHistoryMessages] = useState<HistoryMessageData[]>([]);

    // Modals
    const [clientSelectModalVisible, setClientSelectModalVisible] = useState(false);
    const [isDownloadingPdfModalVisible, setIsDownloadingPdfModalVisible] = useState(false);
    const { addAlert } = useAlert();
    const { getChoiceRef } = useModelRef();
    const dispatch = useDispatch();
    const travelerTypes = getChoiceRef("travelerType");
    const travelTypes = getChoiceRef("travelType");
    const quotationStates: Set<ReservationState> = new Set([
        "new",
        "in_progress",
        "sent",
        "confirmed",
        "lost",
    ] as ReservationState[]);
    const confirmedStates: Set<ReservationState> = new Set([
        "in_progress",
        "res_in_progress",
        "res_sent",
        "confirmed",
        "completed",
        "cancelled",
    ] as ReservationState[]);
    const states = getChoiceRef("reservationState")?.filter((s) =>
        type === "quotation"
            ? quotationStates.has(s.value as ReservationState)
            : confirmedStates.has(s.value as ReservationState)
    );

    // Use same styles and icons as StateBadge
    const stateStyles: Map<string, string> = new Map([
        ["new", "bg-[#ffe4de] text-[#b71d18] font-bold"],
        ["confirmed", "bg-[#ffe4de] text-[#b71d18] font-bold"],
        ["in_progress", "bg-[#d6f4f9] text-[#006c9c] font-bold"],
        ["res_in_progress", "bg-[#d6f4f9] text-[#006c9c] font-bold"],
        ["sent", "bg-[#dff3ea] text-[#1b806a] font-bold"],
        ["res_sent", "bg-[#dff3ea] text-[#1b806a] font-bold"],
        ["lost", "bg-[#edeff1] text-[#6a7987] font-bold"],
        ["cancelled", "bg-[#edeff1] text-[#6a7987] font-bold"],
        ["completed", "bg-[#dff3ea] text-[#1b806a] font-bold"],
    ]);
    const stateIcons: Map<string, string> = new Map([
        ["new", "error"],
        ["confirmed", "error"],
        ["in_progress", "schedule"],
        ["res_in_progress", "schedule"],
        ["sent", "check_circle"],
        ["res_sent", "check_circle"],
        ["lost", "check_circle"],
        ["cancelled", "cancel"],
        ["completed", "place"],
    ]);

    const auth = useAuth();

    const screenTitle = type === "quotation" ? "Presupuestos" : "Reservas";
    const [searchParams] = useSearchParams();
    const quotationsFile = searchParams.get("exp");
    const isFromInboxRequest = searchParams.get("fromInboxRequest");
    let textInputTimer: ReturnType<typeof setTimeout>;

    // TO DO: Find better way to do this
    /* const linkQuotations = document.querySelector(".quotations");
    const linkReservations = document.querySelector(".reservations");
    linkQuotations?.addEventListener("click", () => {
        setReservationData(null);
    });
    linkReservations?.addEventListener("click", () => {
        setReservationData(null);
    }); */

    const buildReservationRow = (reservation: ReservationListData, index: number): ReservationRow => {
        const countries = reservation.countries.filter((c) => c);
        const destinations = (
            <div
                className="flex space-x-1 items-center "
                role="button"
                tabIndex={0}
                aria-label="Destinations"
                onKeyDown={(e) => {
                    e.stopPropagation();
                    setAllTagsVisible(allTagsVisible === index ? null : index);
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setAllTagsVisible(allTagsVisible === index ? null : index);
                }}
            >
                {countries.length === 0 && <p className=" text-grey-light-2">-</p>}
                {countries.length > 0 && <Tag label={countries[0].name} type="yellow" />}

                {countries.length > 1 && (
                    <Tooltip
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    border: "solid #CCC 1px",
                                    color: "black",
                                    background: "white",
                                    fontSize: "0.8em",
                                    padding: "1.2rem",
                                },
                            },
                        }}
                        title={
                            <div className="flex gap-2 flex-wrap max-w-32">
                                {countries.map((tag, i) => (
                                    <Tag key={i} label={tag.name} type="yellow" />
                                ))}
                            </div>
                        }
                        open={allTagsVisible === index}
                    >
                        <span>{`+${countries.length - 1}`}</span>
                    </Tooltip>
                )}
            </div>
        );

        const comments = (
            <Tooltip
                PopperProps={{
                    sx: {
                        "& .MuiTooltip-tooltip": {
                            border: "solid #CCC 1px",
                            color: "black",
                            background: "white",
                            fontSize: "0.8em",
                        },
                    },
                }}
                title={reservation.comments}
                open={commentsTooltipVisible === index}
                onClick={(ev) => {
                    setCommentsTooltipVisible(commentsTooltipVisible === index ? null : index);
                    ev.stopPropagation();
                }}
            >
                <ChatBubble className={`${reservation.comments ? "text-grey" : "text-grey-light-3"}`} />
            </Tooltip>
        );

        return {
            id: reservation.id,
            title: reservation.title,
            first_client_full_name: getAvatarWithName(
                reservation.first_client?.full_name,
                null,
                reservation.first_client?.profile_image?.image
            ),
            destinations,
            state: <StateBadge state={reservation.state} states={states} />,
            travel_start_date: reservation.travel_start_date && reservation.travel_start_date.toString(),
            itinerary_days: reservation.itinerary_days,
            amount_travelers: reservation.amount_travelers,
            comments,
        };
    };
    const fetchReservations = useCallback(
        (searchString) => {
            axiosApi
                .get(
                    `/reservations/?type=${type}&limit=${PAGE_SIZE}&offset=${(currentPage - 1) * PAGE_SIZE}${
                        assignedFilter ? `&assignee=${auth.contact}` : ""
                    }${searchString ? `&query=${searchString}` : ""}`
                )
                .then((response) => {
                    setTotalRecords(response.data.count);
                    setReservations(response.data.results);
                })
                .catch(() => {
                    // FIXME: Using addAlert requires adding it as a dep, which invalidates the callback every time addAlert is called anywhere,
                    // triggering any useEffect that has the fetchReservations function as a dep
                    // addAlert("Error al recuperar las solicitudes.", "error");
                });
        },
        [type, currentPage, assignedFilter, auth.contact]
    );

    const fetchMessages = useCallback(() => {
        if (!reservationData) return;

        axiosApi
            .get(`/reservations/${reservationData.id}/messages/${reservationData.threads[currentChannel].id}/`)
            .then((response) => {
                const messagesSorted: Array<HistoryMessageData> = response.data;
                messagesSorted.sort(
                    (a, b) => new Date(a.create_datetime).getTime() - new Date(b.create_datetime).getTime()
                );
                setHistoryMessages(messagesSorted);
                scrollDownView();
            })
            .catch(() => addAlert("Error al actualizar los mensajes del chat.", "error"));
    }, [addAlert, scrollDownView, currentChannel, reservationData]);

    const fetchCompany = useCallback(() => {
        if (auth.contact === null) return;
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            setCompanyData(data);
        });
    }, [auth.contact]);

    const openReservation = useCallback(
        (id: number) => {
            if (reservationData && !isFromInboxRequest) return;

            // Fetch the full data from the server
            axiosApi
                .get(`/reservations/${id}/`)
                .then((response) => {
                    if (response.data.itinerary) {
                        // Sort itinerary (composed of day blocks) by position
                        const itinerarySorted: Array<DayBlockData> = response.data.itinerary;
                        itinerarySorted.sort((a, b) => a.position - b.position);
                        setItinerary(itinerarySorted);
                        delete response.data.itinerary;
                    }
                    if (response.data.threads) {
                        setHistoryChannels(response.data.threads);
                        const messagesSorted: Array<HistoryMessageData> =
                            response.data.threads[currentChannel].messages;
                        messagesSorted.sort(
                            (a, b) => new Date(a.create_datetime).getTime() - new Date(b.create_datetime).getTime()
                        );
                        setHistoryMessages(messagesSorted);
                        delete response.data.threads[currentChannel].messages;
                    }
                    dispatch(showName());
                    setReservationData(response.data);
                    dispatch(openModel({ modelId: response.data.id }));
                })
                .catch(() => {
                    addAlert("Error al recuperar los detalles del viaje.", "error");
                });

            // If the list of employees is not loaded yet, fetch the company data and get them from there
            if (employees === null) {
                axiosApi.get("/contacts/company/").then((response) => {
                    setEmployees(response.data.employees);
                });
            }
        },
        [addAlert, dispatch, employees, reservationData, currentChannel, isFromInboxRequest]
    );

    const goToLinkedRequest = useCallback(() => {
        if (firstinit.current) {
            quotationsFile && openReservation(Number(quotationsFile));
            firstinit.current = false;
        }
    }, [quotationsFile, openReservation]);

    goToLinkedRequest();

    const handleErrorResponse = useCallback(
        (err: Error | AxiosError, isItinerary = false) => {
            if (axios.isAxiosError(err) && err.response) {
                const newFieldsWithError = new Map<string, string>();
                const { data } = err.response;
                const wrongFields = Object.entries(data);
                switch (err.response.status) {
                    case 400:
                        addAlert("Algunos campos requeridos no han sido correctamente rellenados.", "error");
                        for (let i = 0; i < wrongFields.length; i += 1) {
                            const [field, message] = wrongFields[i];
                            if (Array.isArray(message) && typeof message[0] === "string") {
                                newFieldsWithError.set(`${isItinerary ? "itinerary_" : ""}${field}`, message[0]);
                            }
                        }
                        setFieldErrors(newFieldsWithError);
                        break;
                    default:
                        addAlert(`Ha ocurrido un error inesperado. Código de error: ${err.response.status}`, "error");
                        break;
                }
            }
        },
        [setFieldErrors, addAlert]
    );

    const showSidePanel = () => {
        isSidePanelOpened === true ? setSidePanelIsopen(false) : setSidePanelIsopen(true);
    };

    function reportWindowSize() {
        window.innerWidth < 640 && setSidePanelIsopen(false);
    }

    window.onresize = reportWindowSize;

    const addFilesToUpload = (newFiles: Array<File>) => {
        if (!reservationData || !newFiles) return;

        let existsDuplicate = false;
        const filesToUploadAux = filesToUpload.slice(); // Temporary copy to modify before calling setState
        for (let i = 0; i < newFiles.length; i += 1) {
            // Check for name duplicates.
            // If a duplicate is found, show a warning and don't add the file.
            // An alternative we can implement in the future is renaming the file with the duplicate name (e.g. "name (1)"),
            // overwriting the old file or just allowing duplicate names, since the actual names stored in the database are not the
            // original file names
            const newFile = newFiles[i];
            if (
                reservationData.attachments.find((attachment) => attachment.name === newFile.name) ||
                filesToUpload.find((file) => file.name === newFile.name)
            ) {
                existsDuplicate = true;
            } else {
                filesToUploadAux.push(newFile);
                dispatch(dataPending());
            }
        }

        setFilesToUpload(filesToUploadAux);
        // Show warning if there were duplicates

        if (existsDuplicate) {
            addAlert("Añadir ficheros con nombres duplicados no está permitido.", "warning");
        }
    };

    // TODO: Function is very similar to the one in ClientsScreen, try to refactor it to avoid duplicate code
    const buildAttachmentRow = (attachment: AttachmentData | File, uploaded = true, id = 0): AttachmentRow => {
        const attachmentData = attachment as AttachmentData;

        const actions = uploaded ? (
            <div className="flex space-x-4">
                <IconButton
                    icon="download"
                    onClick={() => {
                        if (!attachmentData?.fs_file) return;

                        // We can't just use a link with a download attribute to modify the filename because of the
                        // same-origin policy (we are downloading a file from the server), so we have to do some
                        // JS bullshit to retrieve it with the custom filename.
                        // See https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
                        axios({
                            url: (attachment as AttachmentData).fs_file,
                            method: "GET",
                            responseType: "blob",
                        }).then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", attachment.name);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                        });
                    }}
                />
                <IconButton
                    icon="delete"
                    iconSize="27px"
                    onClick={() => {
                        // Delete file (and associated attachment record) from the server
                        // TODO: Add verification pop-up, or show as slashed-out and remove when the client is saved,
                        // in a similar way to how upload is done
                        if (id <= 0) return;

                        axiosApi.delete(`/attachments/${id}/`).then(() => {
                            if (reservationData) {
                                setReservationData({
                                    ...reservationData,
                                    attachments: reservationData.attachments.filter((atm) => atm !== attachment),
                                });
                            }
                        });
                    }}
                />
            </div>
        ) : (
            <div className="flex">
                <IconButton
                    icon="close"
                    onClick={() => {
                        // Remove file from the to-upload list
                        setFilesToUpload(filesToUpload.filter((file) => file !== attachment));
                    }}
                />
            </div>
        );

        if (attachmentData !== undefined && uploaded) {
            return {
                ...attachmentData,
                size: formatBytes(attachmentData.size),
                timestamp: formatDate(new Date(attachmentData.timestamp), true, true),
                actions,
            };
        }
        // File type, meaning it has not been uploaded yet
        return {
            name: attachment.name,
            size: formatBytes(attachment.size),
            timestamp: "",
            id,
            actions,
        };
    };

    const createNewReservation = async (client: ModelRef | undefined = undefined) => {
        setIsCreatingNewReservation(true);
        setFirstCountryImage(null);

        const newReservation = {
            id: 0,
            state: "new",
            clients: client ? [client] : [{ id: 0, full_name: "" }],
            title: "",
            destinations: new Array<string>(),
            travel_start_date: formatDateISO(new Date()),
            amount_travelers: 0,
            interests: new Array<ModelRef>(),
            budget: 0,
            included: companyData?.config.included || "",
            not_included: companyData?.config.not_included || "",
            cancellation_policy: companyData?.config.cancellation_policy || "",
            create_datetime: new Date(),
            comments: "",
            attachments: new Array<AttachmentData>(),
            itinerary: new Array<DayBlockData>(),
            currency: "",
            extra_policies: [],
        } as Partial<ReservationDetailData>;

        // Add default extra policies if present
        if (companyData?.config.extra_policies) {
            for (let i = 0; i < companyData?.config.extra_policies.length; i += 1) {
                newReservation.extra_policies?.push({
                    id: getLowestInvalidId(newReservation.extra_policies),
                    title: companyData?.config.extra_policies[i].title,
                    text: companyData?.config.extra_policies[i].text,
                });
            }
        }

        await axiosApi
            .post("/reservations/", newReservation)
            .then((response) => {
                setReservationData(response.data);
                setItinerary(response.data.itinerary);
                dispatch(showName());
                dispatch(openModel({ modelId: response.data.id }));
            })
            .catch((err) => handleErrorResponse(err))
            .finally(() => setIsCreatingNewReservation(false));
    };

    const handleSave = async (data: ReservationDetailData) => {
        if (!reservationData) return;

        // Data preparation and formatting before sending it to the server
        const reservationDataFormatted: Partial<ReservationDetailData> = {
            ...data,
            travel_start_date: formatDateISO(new Date(data.travel_start_date)),
        };
        // If required selection fields are not properly filled, don't send any data so that
        // we get the "this field is required" message
        if (!data.traveler_type) delete reservationDataFormatted.traveler_type;
        if (!data.travel_type) delete reservationDataFormatted.travel_type;

        if (reservationDataFormatted.cover_image) {
            reservationDataFormatted.cover_image = {
                id: reservationDataFormatted.cover_image.id,
                image: reservationDataFormatted.cover_image.image,
                is_moken_image: reservationDataFormatted.cover_image.is_moken_image,
            };
        }

        // Strip unnecessary information. This info is updated elsewhere
        delete reservationDataFormatted.attachments;

        // Upload attachments
        if (filesToUpload) {
            for (let i = 0; i < filesToUpload.length; i += 1) {
                const formData = new FormData();
                formData.append("name", filesToUpload[i].name);
                formData.append("fs_file", filesToUpload[i], filesToUpload[i].name);
                axiosApi
                    .post(`/reservations/${data.id}/upload/`, formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    })
                    .catch(() => addAlert("Ha habido un error subiendo uno o más archivos al servidor", "error"));
            }

            setFilesToUpload([]);
        }

        // Update existing reservation
        await axiosApi.put(`/reservations/${data.id}/`, reservationDataFormatted).then((updatedReservation) => {
            setReservationData(updatedReservation.data);
        });

        setOptionsDropdownVisible(false);
    };

    const handleDuplicate = (id: number) => {
        axiosApi
            .get(`/reservations/${id}/duplicate/`)
            .then(() => {
                addAlert("Se ha duplicado la reserva con éxito.", "success");
            })
            .catch((err) => handleErrorResponse(err));

        setOptionsDropdownVisible(false);
    };

    const handleDelete = (id: number) => {
        // TODO: Show confirmation dialog

        axiosApi
            .delete(`/reservations/${id}/`)
            .then(() => {
                setReservationData(null);
                setCurrentPage(1);
                setReservations(null);
                fetchReservations("");
            })
            .catch((err) => handleErrorResponse(err));

        setOptionsDropdownVisible(false);
        setIsConfirmationModalVisible(false);
    };

    const handleDeleteMultiple = () => {
        if (reservationData || selectedRows.size === 0) return;
        // TODO: Allow deleting multiple items with a single query via the API
        // TODO: Show confirmation dialog
        const idArray = Array.from(selectedRows);
        for (let i = 0; i < idArray.length; i += 1) {
            handleDelete(idArray[i]);
        }
        setSelectedRows(new Set());
    };

    const handleSaveAsTemplate = (id: number) => {
        axiosApi
            .get(`/reservations/${id}/to_template/`)
            .then(() => {
                addAlert("Se ha guardado la reserva como tarifario.", "success");
            })
            .catch((err) => handleErrorResponse(err));

        setOptionsDropdownVisible(false);
    };

    const handleSendHistoryMessage = (attachments: AttachmentData[]): Promise<boolean> => {
        scrollDownView();
        if (!reservationData)
            return new Promise((resolve) => {
                resolve(false);
            });
        return axiosApi.post(`/reservations/${reservationData.id}/add_message/`, {
            thread: reservationData.threads[currentChannel].id,
            message: historyInputValue,
            attachments,
        });
    };

    const handleClientM2oChange = (selected: ContactMinimalData | void) => {
        setReservationData({
            ...reservationData,
            clients: selected ? [selected] : [], // TODO: Allow multiple clients
        } as ReservationDetailData);
    };

    const handleSelectChannel = (channel: number, name: string) => {
        setChannelInfo(name);
        setCurrentChannel(channel);
        scrollDownView();
    };

    const handleDisableConfirmationModal = () => {
        setIsdoubleCheckDisabled(true);
    };

    const handleStorePendingData = (property: string, x: string | number) => {
        setReservationData({ ...reservationData, [property]: x } as ReservationDetailData);
    };

    useEffect(() => {
        scrollDownView();
    }, [scrollDownView]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    // Fetch reservations when page changes
    useEffect(() => {
        setReservations(null);

        fetchCompany();
        fetchReservations("");
        if (!locationState) {
            setItinerary([]);
        }
        setIsCreatingNewReservation(false);
        setFilesToUpload([]);
    }, [currentPage, fetchCompany, fetchReservations, locationState]);

    // Reset everything if we change between quotations and reservations, to reload the view
    useEffect(() => {
        setReservations(null);
        // Only close any open reservations if we don't come from inbox. If we come from inbox it means we just
        // created a reservation from a request and we want it open as soon as we switch to this screen
        if (keepOpen) {
            if (locationState?.client) {
                createNewReservation(locationState.client);
            }
            setKeepOpen(false);
        } else {
            setReservationData(null);
        }
        if (!locationState) {
            setItinerary([]);
        }
        setCurrentPage(1);
        setIsCreatingNewReservation(false);
        setFilesToUpload([]);
        setAssignedFilter(false);
        setSelectedRows(new Set());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, setKeepOpen]);

    // When itinerary data changes, compute the travel end time using the travel start time and travel days
    useEffect(() => {
        if (!reservationData) {
            setIsCreatingNewReservation(false);
            setFieldErrors(new Map());
            setFilesToUpload([]);
            return;
        }

        const travelEndTimeAux = new Date(reservationData.travel_start_date);
        const numDays = getItineraryDays(itinerary);
        travelEndTimeAux.setDate(travelEndTimeAux.getDate() + (numDays > 0 ? numDays - 1 : 0));
        setTravelEndTime(travelEndTimeAux);
    }, [reservationData, itinerary]);

    // When reservation is closed, remove reservation data from the header
    useEffect(() => {
        if (reservationData == null) {
            dispatch(closeModel());
        }
    }, [reservationData, dispatch]);

    useEffect(() => {
        if (reservationData && reservationData?.countries.length > 0) {
            axiosApi
                .get(`/countries/${reservationData.countries.at(0)?.id}/image/`)
                .then((r) => {
                    setFirstCountryImage(r.data);
                })
                .catch(() => {
                    addAlert("Ha habido un error al recuperar la imagen disponible del país", "error");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservationData?.countries]);

    // When a search query is submitted, reset the list and re-fetch reservations using the query
    useEffect(() => {
        setCurrentPage(1);
        setReservationData(null);
    }, []);

    useEffect(() => {
        dispatch(saveData()); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadingScreen = (
        <ScreenTemplate title={screenTitle}>
            <Container>
                <div className="flex items-center justify-center h-full w-full">
                    <Spinner />
                </div>
            </Container>
        </ScreenTemplate>
    );
    if ((!reservations && !reservationData) || !travelerTypes || !travelTypes || !states) {
        return loadingScreen;
    }

    const listView = (
        <div className="pl-8 pr-8 pb-8 w-full overflow-hidden">
            <Container
                numSelected={!reservationData ? selectedRows.size : undefined}
                actionArea={
                    <Button
                        label={`Eliminar ${selectedRows.size} ${selectedRows.size < 2 ? "item" : "items"}`}
                        onClick={handleDeleteMultiple}
                        type="btn_red"
                    />
                }
            >
                <div className="flex flex-col h-full sm:max-h-[calc(100vh-148px)]">
                    <div className="flex flex-col-reverse sm:flex-row md:justify-between w-full lg:space-x-10">
                        <div className="flex w-full flex-wrap md:flex-nowrap mt-4 sm:mt-0">
                            <div className="flex w-full sm:w-auto mr-0 sm:mr-8">
                                <SearchBox
                                    placeholder="Buscar nombre, destino o fecha..."
                                    onChange={(searchString) => {
                                        fetchReservations(searchString);
                                    }}
                                    hiddenIcon
                                />
                            </div>
                            <div className="ml-0 mt-8 mr-0 sm:mr-8 sm:mt-0 min-w-[130px]">
                                <Checkbox
                                    label="Asignado a mí"
                                    checked={assignedFilter}
                                    onChange={() => setAssignedFilter(!assignedFilter)}
                                />
                            </div>
                        </div>
                        <div className="flex w-full sm:w-auto sm:justify-end mt-0">
                            <div className="items-center sm:items-start w-full sm:w-auto">
                                <p className="mt-20 block sm:hidden text-[2.2rem] font-black font-sans text-blue-dark">
                                    {screenTitle}
                                </p>
                                {type === "quotation" && (
                                    <div className="w-full sm:w-max mt-8 sm:mt-0 h-[56px] sm:h-auto ">
                                        <Button
                                            label="Nuevo"
                                            icon="add"
                                            onClick={() => createNewReservation()}
                                            type="btn_dark"
                                            title={`Nuevo ${type === "quotation" ? "presupuesto" : "reserva"}`}
                                            extraClass="w-full h-full"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            " overflow-x-auto h-full pt-8 no-scrollbar",
                            isSidebarOpen
                                ? " md:w-[calc(100vw-384px)]"
                                : "w-[calc(100vw-32px)] sm:w-[calc(100vw-164px)] "
                        )}
                    >
                        {reservations && (
                            <div className="mt-2 min-w-[800px]">
                                <div className="grow ">
                                    <List
                                        objects={reservations.map((reservation, index) =>
                                            buildReservationRow(reservation, index)
                                        )}
                                        fields={reservationFields}
                                        listType="reservationlist"
                                        multiselection
                                        onRowClick={openReservation}
                                        selectedRows={selectedRows}
                                        onSelect={setSelectedRows}
                                    />
                                </div>
                                {totalRecords > PAGE_SIZE && (
                                    <div className="self-end shrink pb-4">
                                        <Pagination
                                            currentPage={currentPage}
                                            resultsPerPage={PAGE_SIZE}
                                            totalResults={totalRecords}
                                            onPrevious={() => setCurrentPage(currentPage - 1)}
                                            onNext={() => setCurrentPage(currentPage + 1)}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );

    const summaryTab = reservationData && (
        <Tab name="General">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <Container>
                    <div className="flex flex-col pt-0 md:pt-4">
                        <h2 className="text-2xl font-bold font-sans">Datos de la solicitud</h2>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-6 pt-6">
                            <div className="col-span-12 md:col-span-5">
                                <TextInput
                                    id="client"
                                    type="text"
                                    label="Cliente"
                                    value={
                                        reservationData.clients?.at(0)?.full_name
                                            ? reservationData.clients?.at(0)?.full_name
                                            : ""
                                    }
                                    onInteract={() => {
                                        setClientSelectModalVisible(!clientSelectModalVisible);
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-4 flex items-end">
                                <Selection
                                    id="traveler_type"
                                    label="Tipo de viajero"
                                    size="small"
                                    choices={travelerTypes}
                                    defaultValue={getChoice(reservationData.traveler_type || "", travelerTypes)}
                                    onChange={(choice) => {
                                        setReservationData({ ...reservationData, traveler_type: choice.value });
                                        dispatch(dataPending());
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <TextInput
                                    id="amount_travelers"
                                    type="number"
                                    label="Pax"
                                    defaultValue={
                                        reservationData.amount_travelers
                                            ? reservationData.amount_travelers.toString()
                                            : ""
                                    }
                                    onChange={(ev) => {
                                        const value = parseInt(ev.currentTarget.value, 10);
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            handleStorePendingData("amount_travelers", value);
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-5">
                                <DateRangePicker
                                    idStart="travel_start_date"
                                    labelStart="Fecha desde"
                                    defaultValueStart={new Date(reservationData.travel_start_date)}
                                    onChangeStart={(selectedDates) => {
                                        if (selectedDates.length === 1) {
                                            setReservationData({
                                                ...reservationData,
                                                travel_start_date: formatDateISO(selectedDates[0]),
                                            });
                                        }
                                        dispatch(dataPending());
                                    }}
                                    idEnd="travel_end_time"
                                    labelEnd="Fecha hasta"
                                    valueEnd={travelEndTime}
                                    disabledEnd
                                />
                            </div>

                            <div className="col-span-12 md:col-span-4">
                                <TextInput
                                    id="budget"
                                    type="number"
                                    label="Presupuesto"
                                    // TODO: Use actual currency
                                    defaultValue={reservationData.budget ? reservationData.budget.toString() : ""}
                                    onChange={(ev) => {
                                        const value = parseFloat(ev.currentTarget.value);
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            handleStorePendingData("budget", value);
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                            <div className="col-span-12 md:col-span-3">
                                <Selection
                                    id="travel_type"
                                    label="Tipo de viaje"
                                    size="small"
                                    choices={travelTypes}
                                    defaultValue={getChoice(reservationData.travel_type || "", travelTypes)}
                                    onChange={(choice) => {
                                        setReservationData({ ...reservationData, travel_type: choice.value });
                                        dispatch(dataPending());
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-6 mt-10">
                            <div className="col-span-12 lg:col-span-9">
                                <div>
                                    <TextInput
                                        id="titleactualizada"
                                        type="text"
                                        label="Título"
                                        defaultValue={reservationData.title}
                                        onChange={(ev) => {
                                            const value = ev.currentTarget.value;

                                            clearTimeout(textInputTimer);
                                            textInputTimer = setTimeout(() => {
                                                handleStorePendingData("title", value);
                                                dispatch(dataPending());
                                            }, 600);

                                            return () => clearTimeout(textInputTimer);
                                        }}
                                    />
                                    <div className="mt-6">
                                        <TextInput
                                            id="comments"
                                            type="text"
                                            label="Comentarios cliente"
                                            defaultValue={reservationData.comments}
                                            lines={5}
                                            onChange={(ev) => {
                                                const value = ev.currentTarget.value;
                                                clearTimeout(textInputTimer);
                                                textInputTimer = setTimeout(() => {
                                                    handleStorePendingData("comments", value);
                                                    dispatch(dataPending());
                                                }, 600);

                                                return () => clearTimeout(textInputTimer);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col col-span-12 lg:col-span-3 min-h-[200px]">
                                <h4 className="font-semibold text-grey text-sm pb-1">Portada</h4>
                                <ImageSelector
                                    image={reservationData.cover_image || firstCountryImage}
                                    onAccept={(image: MokenImage) => {
                                        setReservationData({ ...reservationData, cover_image: image });
                                        dispatch(dataPending());
                                    }}
                                    mokenImage={firstCountryImage || ({ id: 0, image: "" } as MokenImage)}
                                    description={
                                        reservationData.cover_image?.is_unsplash
                                            ? `${reservationData.cover_image.author} (Unsplash)`
                                            : undefined
                                    }
                                    linkTo={
                                        reservationData.cover_image?.is_unsplash
                                            ? reservationData.cover_image.author_url
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                        <div className=" mt-6">
                            <TagGroup title="Preferencias" values={reservationData.interests} />
                        </div>
                    </div>
                </Container>
                <div>
                    <PricingSection
                        currency={reservationData.currency}
                        salesOrder={reservationData.sales_order}
                        onModifySalesOrders={(sales_order) => {
                            setReservationData({ ...reservationData, sales_order });
                        }}
                        onModifyCurrency={(currency) => {
                            setReservationData({ ...reservationData, currency });
                            dispatch(dataPending());
                        }}
                        importedTemplates={reservationData.imported_templates.concat(
                            reservationData.imported_reservations
                        )}
                    />
                </div>
            </div>

            {/* Modals */}
            <ClientSelectModal
                visible={clientSelectModalVisible}
                visibleHandler={setClientSelectModalVisible}
                selected={reservationData.clients?.at(0)?.id}
                onAccept={handleClientM2oChange}
            />
            <LoadingModal visible={isCreatingNewReservation} text="Espera mientras se crea la nueva reserva..." />
        </Tab>
    );

    const destinationsTab = reservationData && (
        <Tab name="Destinos">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <DestinationsTab
                    itinerary={itinerary}
                    setItinerary={setItinerary}
                    setReservation={setReservationData}
                    templateRequests={reservationData.template_requests}
                    reservation={reservationData}
                    type="reservations"
                    selectedVersion={reservationData.selected_version}
                />
            </div>
        </Tab>
    );

    const itineraryTab = reservationData && (
        <Tab name="Itinerario">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <ItineraryTab
                    reservationData={reservationData}
                    isSidePanelOpened={isSidePanelOpened}
                    itinerary={itinerary}
                    setItinerary={setItinerary}
                    reservationId={reservationData.id}
                    type="reservations"
                    selectedVersion={reservationData.selected_version}
                />
            </div>
        </Tab>
    );

    const termsTab = reservationData && (
        <Tab name="Términos">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <TermsTab
                    reservationData={reservationData}
                    setReservationData={(data) => setReservationData(data as ReservationDetailData)}
                />
            </div>
        </Tab>
    );

    const historyTab = reservationData && (
        <Tab name="Chat">
            <div
                className="overflow-y-scroll no-scrollbar scroll-smooth"
                onLoad={() => scrollDownView()}
                ref={scrollableSection}
            >
                <HistoryInput
                    isSidePanelOpened={isSidePanelOpened}
                    reservationData={reservationData}
                    placeholder="Introduce aquí tu mensaje"
                    onSend={handleSendHistoryMessage}
                    onChange={(message) => {
                        setHistoryInputValue(message);
                    }}
                    onEndSend={fetchMessages}
                    onSelectChannel={handleSelectChannel}
                    historyChannels={historyChannels}
                    currentConversationName={channelInfo}
                />

                <div className="flex flex-col justify-center items-center space-y-10 pt-10">
                    <div className="flex flex-col space-y-4 w-full min-w-max h-full mb-20">
                        {historyMessages.map((historyMessage: HistoryMessageData, idx: number) => (
                            <HistoryMessage
                                key={idx}
                                side={historyMessage.owner.id === auth.contact ? "right" : "left"}
                                type={historyMessage.is_notification ? "notification" : "message"}
                                image={historyMessage.owner.profile_image?.image}
                                message={historyMessage.message}
                                timestamp={new Date(historyMessage.create_datetime)}
                                attachments={historyMessage.attachments}
                                highlighted={historyMessage.attachments && historyMessage.attachments.length > 0}
                                senderName={historyMessage.owner.full_name}
                                senderCompany={historyMessage.owner.company}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Tab>
    );
    const updateStatusInHeader = () => {
        reservationData &&
            setReservationData({
                ...reservationData,
                state: "sent" as ReservationState,
            });
    };

    const documentsTab = reservationData && (
        <Tab name="Documentación">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <Container>
                    <div className="pt-10 space-y-10 max-w-[1024px]">
                        <div className="flex w-full">
                            <div className="w-full">
                                <FileUploadZone onChangeFiles={addFilesToUpload} multiple />
                            </div>
                        </div>
                        {/* TODO: Paginate results */}
                        <List
                            listType="documlist"
                            objects={(() => {
                                const attachmentRows = reservationData.attachments.map((attachment) =>
                                    buildAttachmentRow(attachment, true, attachment.id)
                                );
                                attachmentRows.push(
                                    ...filesToUpload.map((attachment, idx) =>
                                        buildAttachmentRow(attachment, false, -idx)
                                    )
                                );
                                return attachmentRows;
                            })()}
                            fields={attachmentFields}
                        />
                    </div>
                </Container>
            </div>
        </Tab>
    );

    const sendTab = reservationData && (
        <Tab name="Enviar">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth">
                <SendTab reservationData={reservationData} updateStatusInHeader={updateStatusInHeader} />
            </div>
        </Tab>
    );

    const detailView = reservationData && (
        <div>
            <div
                className={classNames(
                    "block sm:fixed right-0 w-full px-8  bg-white z-20 transition-left ease-in-out duration-200 justify-between mb-7 z-8 mt-2",
                    isSidebarOpen
                        ? "sm:left-80 sm:w-[calc(100%-20rem)]"
                        : "xs:left-0 sm:left-20 sm:w-[calc(100%-100px)]"
                )}
            >
                <h2 className="block sm:hidden text-[2.2rem] mt-32 font-black font-sans text-blue-dark">{`Presupuestos/${reservationData.id}`}</h2>
                <div className="sm:mt-0 flex flex-col-reverse sm:flex-row justify-between md:flex-nowrap w-full mt-8 md:mt-0">
                    <div className="flex flex-col items-start md:flex-row md:items-center justify-between  md:pr-10 w-full md:w-auto gap-4 grow">
                        <div className="w-full sm:max-w-[180px] basis-full mt-8 sm:mt-0">
                            <Selection
                                size="small"
                                id="status"
                                label="status"
                                choices={states}
                                choiceStyles={stateStyles}
                                choiceIcons={stateIcons}
                                /* renderValue={getChoice(reservationData.state || "", states)} */
                                defaultValue={getChoice(reservationData.state || "", states)}
                                onChange={(choice) => {
                                    setReservationData({
                                        ...reservationData,
                                        state: choice.value as ReservationState,
                                    });
                                    dispatch(dataPending());
                                }}
                            />
                        </div>
                        <div className="pt-8 md:pt-0 w-full grow">
                            <AssigneeSelection
                                defaultValue={reservationData.assignee}
                                choices={employees || []}
                                onChange={(selected) => {
                                    setReservationData({ ...reservationData, assignee: selected });
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row flex-wrap w-full md:w-auto justify-between md:justify-end space-x-5">
                        <div className="flex flex-col-reverse sm:flex-row gap-4">
                            <div className="h-full sm:ml-4 w-full">
                                <Dropdown
                                    anchor={
                                        <Button
                                            label="Opciones"
                                            icon="more_vert"
                                            type="btn_transparent"
                                            title={
                                                type === "quotation" ? "Opciones de presupuesto" : "Opciones de reserva"
                                            }
                                            extraClass="w-full h-[56px] sm:h-auto"
                                        />
                                    }
                                    visible={optionsDropdownVisible}
                                    onClick={() => setOptionsDropdownVisible(!optionsDropdownVisible)}
                                    onBlur={(e) => {
                                        const currentTarget = e.currentTarget;
                                        setTimeout(() => {
                                            if (!currentTarget.contains(document.activeElement)) {
                                                setOptionsDropdownVisible(false);
                                            }
                                        }, 0);
                                    }}
                                >
                                    <DropdownItem
                                        key="print_template"
                                        label="Previsualizar"
                                        onClick={async () => {
                                            await handleSave(reservationData);
                                            setOptionsDropdownVisible(false);
                                            setIsDownloadingPdfModalVisible(true);
                                            axiosApi
                                                .get(`/reservations/${reservationData.id}/print/`, {
                                                    responseType: "blob",
                                                })
                                                .then((response) => {
                                                    download(
                                                        response.data,
                                                        getItineraryPdfName(reservationData),
                                                        response.headers["content-type"]
                                                    );
                                                    setIsDownloadingPdfModalVisible(false);
                                                })
                                                .catch(() => setIsDownloadingPdfModalVisible(false));
                                        }}
                                    />
                                    <DropdownItem
                                        key="download_simple_text"
                                        label="Descargar texto simple"
                                        onClick={async () => {
                                            await handleSave(reservationData);
                                            setOptionsDropdownVisible(false);
                                            axiosApi
                                                .get(`/reservations/${reservationData.id}/print_txt/`, {
                                                    responseType: "blob",
                                                })
                                                .then((response) => {
                                                    download(
                                                        response.data,
                                                        `${getItineraryPdfName(reservationData)}.txt`,
                                                        response.headers["content-type"]
                                                    );
                                                });
                                        }}
                                    />
                                    <DropdownItem
                                        key="duplicate"
                                        label="Duplicar expediente"
                                        onClick={() => {
                                            handleDuplicate(reservationData.id);
                                        }}
                                    />
                                    <DropdownItem
                                        key="save_as_template"
                                        label="Guardar como tarifario"
                                        onClick={() => {
                                            handleSaveAsTemplate(reservationData.id);
                                        }}
                                    />
                                    <DropdownItem
                                        key="delete"
                                        label="Eliminar expediente"
                                        labelColor="red"
                                        onClick={() => {
                                            handleDelete(reservationData.id);
                                        }}
                                    />
                                </Dropdown>
                            </div>
                            <div className="w-full sm:w-auto h-[56px] sm:h-auto relative">
                                <Button
                                    label="Guardar"
                                    onClick={() => {
                                        handleSave(reservationData);
                                        dispatch(saveData());
                                    }}
                                    type="btn_dark"
                                    title={type === "quotation" ? "Guardar presupuesto" : "Guardar reserva"}
                                    extraClass="w-full h-full sm:h-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden pt-6 md:flex w-full justify-end">
                    <button
                        type="button"
                        onClick={() => showSidePanel()}
                        className="flex justify-end text-yellow"
                        title="Mostrar sidebar"
                    >
                        {isSidePanelOpened ? `Ocultar resumen ${" >"}` : `${"< "} Mostrar resumen`}
                    </button>
                </div>
            </div>
            <div className="pt-8 pb-0 px-8 overflow-y-hidden">
                <div className="flex w-full h-full justify-between pt-0 sm:pt-32 md:pt-12 mt-1 sm:h-[calc(100vh-180px)]">
                    <div className=" w-full pt-0 md:pt-8 ">
                        <TabController
                            lockReason="Guarda los cambios en el nuevo día antes de cambiar de pestaña."
                            onChangeTab={scrollDownView}
                        >
                            {summaryTab}
                            {destinationsTab}
                            {itineraryTab}
                            {termsTab}
                            {historyTab}
                            {documentsTab}
                            {sendTab}
                        </TabController>
                    </div>
                    <ReservationPanel
                        isSidePanelOpened={isSidePanelOpened}
                        reservationData={reservationData}
                        itinerary={itinerary}
                        travelTypes={travelTypes}
                        selectedVersion={reservationData.selected_version}
                        setSelectedVersion={(version: number) => {
                            const modifiedData = { ...reservationData, selected_version: version };
                            setReservationData(modifiedData);
                            // Need to perform a save now so that the selected_version is currently set in the backend
                            // for any logic using it, like moving days etc, so that we dont have to redo it here
                            handleSave(modifiedData);
                        }}
                        versions={reservationData.versions}
                    />
                </div>

                <LoadingModal
                    visible={isDownloadingPdfModalVisible}
                    text="El PDF se está generando. La descarga comenzará en breve..."
                />
            </div>
            <ConfirmationModal
                heading="Eliminar día"
                message="¿Estás seguro de que quieres eliminar este expediente?"
                acceptBtnText="Borrar"
                cancelBtnText="Cancelar"
                onDisable={() => handleDisableConfirmationModal()}
                visible={isConfirmationModalVisible}
                disabled={isDoubleCheckDisabled}
                visibleHandler={setIsConfirmationModalVisible}
                onAccept={() => handleDelete(reservationData.id)}
                onCancel={() => setIsConfirmationModalVisible(false)}
                size="sm"
            />
        </div>
    );

    return (
        <ScreenTemplate
            title={screenTitle}
            backButtonVisible={reservationData !== null}
            onBack={() => {
                fetchReservations("");
                setReservationData(null);
                dispatch(saveData());
            }}
        >
            {reservationData ? detailView : listView}
        </ScreenTemplate>
    );
};

export default ReservationScreen;
