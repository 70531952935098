import React, { useState } from "react";
import SlideDown from "react-slidedown";
import classNames from "classnames";
import ListSelectionButton from "./ListSelectionButton";
import IconButton from "../IconButton";

export interface RowProps<Type> {
    id?: number;
    /**
     * Object to show in the row. It corresponds to an instance of one of
     * the objects passed to the List component.
     */
    object: Type;
    fields: {
        key: keyof Type;
        title: string;
    }[];

    /**
     * Whether this row should have a selected styling.
     * The selection logic is managed by the parent List.
     */
    selected: boolean;

    /**
     * Whether the row should have a select button on the left.
     * Inherited by the parent List.
     */
    hasSelectButton: boolean;

    /**
     * What happens when the row itself is clicked (and not some icon or the select button).
     * It will usually open the detailed page of whatever record the row represents.
     */
    onClick?: () => void;

    /**
     * What happens when the select button on the left is clicked, if the list has multiselection.
     */
    onSelect?: () => void;

    /**
     * Whether to use a grab cursor when hovering over this row.
     */
    cursorGrab?: boolean;

    /**
     * Items to list under the row when the expand button is pressed.
     */
    items?: React.ReactNode[];
    /**
     * if present, it sets the number of columns of the table
     */
    listType?: string;
}

/**
 * A single row in a List component. It shows the actual values of the fields in the
 * object that is being treated.
 */
function Row<Type>({
    id,
    object,
    fields,
    selected,
    listType,
    onClick,
    onSelect,
    items,
    hasSelectButton = false,
    cursorGrab = false,
}: RowProps<Type>): React.ReactElement {
    const [collapsed, setCollapsed] = useState(true);
    let gridClass = "";
    let fieldToHide1 = "";
    let fieldToHide2 = "";
    let fieldToHide3 = "";
    let fieldToHide4 = "";
    let responsiveClass = "";

    switch (listType) {
        case "travellist":
            gridClass = "grid-cols-travellist--responsive lg:grid-cols-travellist";
            fieldToHide1 = "reference";
            responsiveClass = "hidden lg:block";
            break;
        case "documlist":
            gridClass = "grid-cols-documlist--responsive lg:grid-cols-documlist";
            fieldToHide1 = "size";
            responsiveClass = "hidden lg:block";
            break;
        case "reservationlist":
            gridClass = "grid-cols-reservationlist--responsive xl:grid-cols-reservationlist";
            fieldToHide1 = "title";
            fieldToHide2 = "amount_travelers";
            fieldToHide3 = "id";
            responsiveClass = "hidden xl:block";
            break;
        case "clientlist":
            gridClass = "grid-cols-clientlist--responsive xl:grid-cols-clientlist";
            fieldToHide1 = "moken";
            fieldToHide2 = "rating";
            responsiveClass = "hidden xl:block";
            break;
        case "tarifarios":
            gridClass = "grid-cols-tarifarios--responsive xl:grid-cols-tarifarios";
            fieldToHide1 = "moken";
            fieldToHide2 = "rating";
            responsiveClass = "hidden xl:block";
            break;
        case "tarifarios-providers":
            gridClass = "grid-cols-tarifarios-providers--responsive xl:grid-cols-tarifarios-providers";
            fieldToHide1 = "travel_type";
            responsiveClass = "hidden xl:block";
            break;
        case "destinationlist":
            gridClass = "grid-cols-destinationlist--responsive xl:grid-cols-destinationlist";
            fieldToHide1 = "external";
            fieldToHide2 = "provider";
            responsiveClass = "hidden xl:block";
            break;
        case "importItinerarylist":
            gridClass = "grid-cols-importItinerarylist--responsive xl:grid-cols-importItinerarylist";
            fieldToHide1 = "itinerary_days";
            fieldToHide2 = "travel_type";
            responsiveClass = "hidden xl:block";
            break;
        case "templatelist":
            gridClass = "grid-cols-templatelist--responsive xl:grid-cols-templatelist";
            fieldToHide1 = "travel_type";
            fieldToHide2 = "public";
            responsiveClass = "hidden xl:block";
            break;
        case "tempdestinationlist":
            gridClass = "grid-cols-tempdestinationlist--responsive lg:grid-cols-tempdestinationlist";
            fieldToHide1 = "days";
            responsiveClass = "hidden lg:block";
            break;
        case "inboxlist":
            gridClass = "grid-cols-inboxlist--responsive xl:grid-cols-inboxlist";
            fieldToHide1 = "reference";
            fieldToHide2 = "amount_travelers";
            responsiveClass = "hidden xl:block";
            break;
        case "userlist":
            gridClass = "grid-cols-userlist";
            responsiveClass = "hidden xl:block";
            break;
        case "morecontactlist":
            gridClass = "grid-cols-morecontactlist--responsive sm:grid-cols-morecontactlist";
            fieldToHide1 = "type";
            fieldToHide2 = "destinations";
            fieldToHide3 = "specialties";
            fieldToHide4 = "languages";
            responsiveClass = "hidden sm:block";
            break;
        case "teamlist":
            gridClass = "grid-cols-teamlist";
            break;
        case "askproviderlist":
            gridClass = "grid-cols-askproviderlist--responsive xl:grid-cols-askproviderlist";
            fieldToHide1 = "external";
            fieldToHide2 = "provider";
            responsiveClass = "hidden xl:block";
            break;
        default:
            gridClass = "grid-cols-clientlist--responsive xl:grid-cols-clientlist";
            responsiveClass = "hidden xl:block";
            break;
    }
    return (
        <div
            id={id?.toString()}
            className={classNames(
                "listrow-item flex flex-col items-center w-full rounded-r-md rounded-l-md",
                cursorGrab ? "cursor-grab" : onClick && "cursor-pointer",
                selected && "bg-yellow-light-2 bg-opacity-30",
                !selected && "hover:bg-blue-light-4 transition ease-in-out delay-30"
            )}
        >
            <div className="flex items-center font-sans text-sm w-full">
                {hasSelectButton && onSelect && <ListSelectionButton selected={selected} onClick={onSelect} />}

                <div className={`grid ${gridClass} auto-cols-fr items-center w-full h-12`}>
                    {fields.map((field) => (
                        <div
                            role="gridcell"
                            // FIXME: We need a way to avoid elements being squished but without allowing overlap of
                            // the elements. If we use min-w-max here, then there is overlap as the screen size decreases, and if
                            // we use min-w-max outside, the rows become way too big as they for some reason occupy more space than
                            // they require
                            className={classNames(
                                "w-full items-center justify-start p-1 truncate min-w-[0] max-w-[100%]",
                                (field.key === fieldToHide1 ||
                                    field.key === fieldToHide2 ||
                                    field.key === fieldToHide3 ||
                                    field.key === fieldToHide4) &&
                                    responsiveClass
                            )}
                            key={String(field.key)}
                            onClick={onClick}
                            onKeyPress={onClick}
                            tabIndex={0}
                        >
                            {object[field.key] || <p className=" text-grey-light-2">-</p>}
                        </div>
                    ))}
                </div>
                {items &&
                    (items.length ? (
                        <div className="w-10 ">
                            <IconButton
                                icon={collapsed ? "chevron_right" : "expand_more"}
                                onClick={() => setCollapsed(!collapsed)}
                                extraClass="text-sm absolute top-1"
                            />
                        </div>
                    ) : (
                        <div className="w-10 px-2" />
                    ))}
            </div>
            {items && (
                <div className="flex w-full pl-14 text-grey-light-1">
                    <SlideDown closed={collapsed} transitionOnAppear={false}>
                        <div className="flex flex-col space-y-2 pb-2">{items}</div>
                    </SlideDown>
                </div>
            )}
        </div>
    );
}

export default Row;
