/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useFilePicker } from "use-file-picker";
import Icon from "@mui/material/Icon";
import IconButton from "./IconButton";
import Avatar from "./Avatar";
import Dropdown from "./Dropdown/Dropdown";
import DropdownItem from "./Dropdown/DropdownItem";
import { useAuth } from "../auth";
import axiosApi from "../axiosApi";
import { getInitials } from "../utils";
import Button from "./Button";
import ConnectionRequestModal from "./modals/ConnectionRequestModal";
import BackButton from "./BackButton";
import { ContactData, NotificationData } from "../types";
import { RootState } from "../store";
import { open } from "../redux/sidebar";
import useAlert from "../useAlert";

export interface MokenHeaderProps {
    /**
     * Title of the current page, to be shown in as the header text.
     */
    title: string;

    /**
     * Whether the back button is visible or not.
     */
    backButtonVisible?: boolean;

    /**
     * What happens when the back button is clicked.
     */
    onBack?: () => void;
}

/**
 * Header of the Moken platform, which tells us the current page we are in, and has some buttons
 * to access new notifications, connection requests, etc, as well as the user's avatar which on
 * click will redirect to the user's settings.
 */
const MokenHeader: React.FC<MokenHeaderProps> = ({ title, backButtonVisible = false, onBack }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [contact, setContact] = useState<ContactData | null>(null);
    const [connectionRequests, setConnectionRequests] = useState<ContactData[]>([]);
    const [connectionRequestDropdownVisible, setConnectionRequestDropdownVisible] = useState(false);
    const [connectionRequestModalVisible, setConnectionRequestModalVisible] = useState(false);
    const [avatarDropdownVisible, setAvatarDropdownVisible] = useState(false);
    const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined);
    const [avatarText, setAvatarText] = useState("");
    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationDropdownVisible, setNotificationDropdownVisible] = useState(false);
    const [documentationDropdownVisible, setDocumentationDropdownVisible] = useState(false);
    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    const avatarOnClick = () => setAvatarDropdownVisible(!avatarDropdownVisible);

    const { addAlert } = useAlert();
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const isFileNameVisible = useSelector((state: RootState) => state.header.nameVisibility);
    const modelId = useSelector((state: RootState) => state.header.modelId);
    const modelName = useSelector((state: RootState) => state.header.modelName);
    const dispatch = useDispatch();
    const isSavingPending = useSelector((state: RootState) => state.reservations.isdataPending);

    const [openFileSelector, { plainFiles, loading, errors }] = useFilePicker({
        accept: "image/*",
        maxFileSize: 5, // in MB
        multiple: false,
    });

    // TODO: Keep state somewhere else so that it is not fetched at every page change
    const fetchContact = useCallback(() => {
        if (auth.contact === null) return;
        axiosApi.get("/contacts/me/").then((response) => {
            const contactData = response.data as ContactData;
            setContact(contactData);
            // Set some contact data
            setAvatarImage(contactData.profile_image?.image);
            setAvatarText(getInitials(contactData.full_name));
            setConnectionRequests(contactData.requests);
        });
    }, []);

    const countNotifications = () => {
        if (notifications.length === 0) {
            return;
        }
        let count = 0;
        notifications.forEach((notification) => {
            if (!notification.seen) {
                count += 1;
            }
        });
        setNotificationCount(count);
    };

    const fetchNotifications = useCallback(async () => {
        if (auth.user === null) return;
        const ac = new AbortController();
        axiosApi.get("/notifications/").then((response) => {
            const notificationData = response.data as NotificationData[];
            setNotifications(notificationData);
        });
        ac.abort();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchNotifications();
        }, 4000);
        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        countNotifications();
    }, [notifications]);

    const markNotificationsAsSeen = () => {
        notifications.forEach((notification) => {
            axiosApi.patch(`/notifications/${notification.id}/`, { seen: true });
        });
        setNotifications(notifications.map((n) => ({ ...n, seen: true } as NotificationData)));
    };

    const handleNotificationDropdownOnClick = () => {
        setNotificationDropdownVisible(!notificationDropdownVisible);
        markNotificationsAsSeen();
    };
    const handleDocumentationDropdownOnClick = () => {
        setDocumentationDropdownVisible(!documentationDropdownVisible);
    };

    const deleteNotification = (id: number) => {
        axiosApi.delete(`/notifications/${id}/`);
        fetchNotifications();
    };

    const onAcceptConnection = (id: number) => {
        axiosApi.get(`/contacts/${id}/accept/`);
        setConnectionRequests(connectionRequests.filter((con) => con.id !== id));
    };

    const onIgnoreConnection = (id: number) => {
        axiosApi.get(`/contacts/${id}/reject/`);
        setConnectionRequests(connectionRequests.filter((con) => con.id !== id));
    };

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            if (!contact) {
                fetchContact();
            }
        }

        return () => {
            isMounted = false;
        };
    }, []);

    // Uplaod profile image
    useEffect(() => {
        if (contact !== null && !loading && errors.length === 0 && plainFiles.length === 1) {
            const file = plainFiles[0];
            const formData = new FormData();
            formData.append("name", file.name);
            formData.append("file", file, file.name);
            axiosApi
                .post(`/contacts/${contact.id}/upload_user_image/`, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then((r) => {
                    setAvatarImage(r.data.image);
                    addAlert("Se ha cambiado la imagen de perfil.", "success");
                })
                .catch(() => addAlert("Hubo un error al cargar la imagen.", "error"));

            // Clear files to upload
            plainFiles.length = 0;
        }
    }, [plainFiles, loading, errors]);

    const avatarComponent = <Avatar image={avatarImage} altText={avatarText} size="xl" title="Menú de usuario" />;
    const connectionRequestsIcon = (
        <IconButton
            icon="group"
            color={connectionRequestDropdownVisible ? "yellow" : "grey"}
            numberBadge={connectionRequests.length}
            title="Abrir solicitudes"
        />
    );

    const connectionRequestsBox = (
        <div className="flex flex-col p-5 w-96 min-w-min">
            <h3 className="text-xl font-bold mb-1">Solicitudes de contacto</h3>
            {connectionRequests?.length > 0 ? (
                <>
                    <button
                        type="button"
                        className="text-yellow-dark-1 font-bold mb-5 self-end cursor-pointer"
                        onClick={() => {
                            setConnectionRequestDropdownVisible(false);
                            setConnectionRequestModalVisible(true);
                        }}
                    >
                        Ver todas
                    </button>
                    {connectionRequests.map((conn) => (
                        <div className="flex space-x-5 mb-5" key={conn.id}>
                            <Avatar image={conn.profile_image?.image} altText={getInitials(conn.full_name)} />
                            <div className="flex flex-col space-y-1">
                                <span className="font-bold">{conn.full_name}</span>
                                <span className="text-sm">
                                    {conn.common_connections?.length || 0} contactos en común
                                </span>
                                <div className="flex space-x-3">
                                    <Button
                                        label="ACEPTAR"
                                        type="btn_yellow"
                                        onClick={() => onAcceptConnection(conn.id)}
                                    />
                                    <Button
                                        label="IGNORAR"
                                        type="btn_transparent"
                                        onClick={() => onIgnoreConnection(conn.id)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <p className="text-grey text-sm">No tienes ninguna solicitud de conexión.</p>
            )}
        </div>
    );

    const documentationIcon = (
        <IconButton icon="help_outlined" color={documentationDropdownVisible ? "yellow" : "grey"} title="Abrir info" />
    );

    const notificationIcon = (
        <IconButton
            icon="notifications"
            color={notificationDropdownVisible ? "yellow" : "grey"}
            numberBadge={notificationCount}
            title="Abrir notificaciones"
        />
    );

    const notificationBox = (
        <div className="flex flex-col p-5 w-96 min-w-min">
            <h3 className="text-xl font-bold mb-4 pl-3 pt-1">Notificaciones</h3>

            {notifications?.length > 0 ? (
                <div className="h-96 overflow-y-auto space-y-7">
                    Request
                    {notifications.map((notification) => {
                        let webSection = "";

                        if (notification.linked_model?.id) {
                            if (notification.linked_model.model === "ReservationRequest") {
                                webSection = "inbox";
                            } else if (notification.linked_model.model === "Reservation") {
                                webSection = "reservations";
                            }
                            return (
                                <a
                                    href={`/${webSection}?exp=${notification.linked_model.id}`}
                                    className="block"
                                    key={notification.id}
                                >
                                    <div className="flex mb-5 hover:bg-yellow-light-1 p-2 rounded-md">
                                        <div className="flex flex-row justify-between items-center w-full">
                                            <div className="flex items-center">
                                                {notification.sender && notification.sender.profile_image && (
                                                    <div className="min-w-min pl-1">
                                                        <Avatar
                                                            size="xl"
                                                            image={notification.sender.profile_image.image}
                                                            altText="!"
                                                        />
                                                    </div>
                                                )}
                                                <div className="px-4">
                                                    <div>{notification.title}</div>
                                                    <div className="text-yellow">
                                                        {notification.sender && notification.sender.full_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" relative left-1">
                                                <IconButton
                                                    color="red"
                                                    icon="delete"
                                                    iconSize="27px"
                                                    onClick={() => deleteNotification(notification.id)}
                                                    extraClass="stroke-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            );
                        }
                        return (
                            <div className="flex mb-5 hover:bg-yellow-light-1 p-2 rounded-md" key={notification.id}>
                                <div className="flex flex-row justify-between items-center w-full">
                                    <div className="flex items-center">
                                        {notification.sender && notification.sender.profile_image && (
                                            <div className="min-w-min pl-1">
                                                <Avatar
                                                    size="xl"
                                                    image={notification.sender.profile_image.image}
                                                    altText="!"
                                                />
                                            </div>
                                        )}
                                        <div className="px-4">
                                            <div>{notification.title}</div>
                                            <div className="text-yellow">
                                                {notification.sender && notification.sender.full_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" relative left-1">
                                        <IconButton
                                            color="red"
                                            icon="delete"
                                            iconSize="27px"
                                            onClick={() => deleteNotification(notification.id)}
                                            extraClass="stroke-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="text-grey text-sm">No tienes ninguna notificación.</p>
            )}
        </div>
    );

    const documentationBox = (
        <DropdownItem
            label="Documentación"
            onClick={() => {
                window.open("https://docs.themoken.app");
            }}
        />
    );

    let modelDetail;
    if (modelName) {
        modelDetail = modelName;
    } else if (modelId >= 0) {
        modelDetail = modelId;
    }

    return (
        <div>
            <div
                className={classNames(
                    "absolute sm:fixed w-full top-0 flex right-0 h-28 items-center justify-between px-8 py-6 bg-white z-40 transition-left ease-in-out duration-200",
                    isSidebarOpen
                        ? "sm:left-80 sm:w-[calc(100%-20rem)]"
                        : "xs:left-0 sm:left-20 sm:w-[calc(100%-100px)]"
                )}
            >
                {!isSidebarOpen && (
                    <button
                        className="pr-6"
                        onClick={() => {
                            dispatch(open());
                        }}
                        type="button"
                    >
                        <Icon className="text-blue">menu</Icon>
                    </button>
                )}
                {backButtonVisible && <BackButton onClick={() => onBack && onBack()} />}
                <span
                    className={classNames(
                        "hidden sm:flex flex-1 text-3xl font-black font-sans text-blue-dark pl-8 sm:pl-0",
                        backButtonVisible && "sm:pl-5"
                    )}
                >
                    {title}
                    {isFileNameVisible && modelDetail && (
                        <>
                            {" "}
                            /{" "}
                            <span className=" text-grey-light-1 font-normal pl-2 self-end  tracking-tight">
                                {" "}
                                {modelDetail}
                            </span>
                        </>
                    )}
                    {isSavingPending && <span className="text-sm text-grey-light-1 font-normal pl-1  ">*</span>}
                </span>
                <div className="flex flex-1 items-center justify-end space-x-4 sm:space-x-10">
                    <Dropdown
                        anchor={connectionRequestsIcon}
                        placement="bottom"
                        visible={connectionRequestDropdownVisible}
                        onClick={() => setConnectionRequestDropdownVisible(!connectionRequestDropdownVisible)}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setConnectionRequestDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        {connectionRequestsBox}
                    </Dropdown>
                    <Dropdown
                        anchor={notificationIcon}
                        placement="bottom"
                        visible={notificationDropdownVisible}
                        onClick={handleNotificationDropdownOnClick}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setNotificationDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        {notificationBox}
                    </Dropdown>
                    <Dropdown
                        anchor={documentationIcon}
                        placement="bottom"
                        visible={documentationDropdownVisible}
                        onClick={handleDocumentationDropdownOnClick}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setDocumentationDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        {documentationBox}
                    </Dropdown>
                    <Dropdown
                        anchor={avatarComponent}
                        placement="left"
                        visible={avatarDropdownVisible}
                        onClick={avatarOnClick}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setAvatarDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        <DropdownItem
                            label="Subir imagen"
                            onClick={() => {
                                openFileSelector();
                                setAvatarDropdownVisible(false);
                            }}
                        />
                        <DropdownItem
                            label="Documentación"
                            onClick={() => {
                                window.open("https://docs.themoken.app");
                            }}
                        />
                        <DropdownItem
                            label="Log out"
                            onClick={() => {
                                auth.logOut();
                                navigate("/login");
                            }}
                        />
                    </Dropdown>
                </div>

                {/* Modals */}
                <ConnectionRequestModal
                    visible={connectionRequestModalVisible}
                    visibleHandler={setConnectionRequestModalVisible}
                    contacts={connectionRequests}
                    onAccept={onAcceptConnection}
                    onIgnore={onIgnoreConnection}
                />
            </div>
        </div>
    );
};

export default MokenHeader;
